<template>
  <div>
    <div class="title">Contact Support </div>
<br>
      <p class="all"> To contact support, please send an email with your login and the issue description to support@altosio.com </p>
  </div>
</template>

<script>

export default {
   name: "ContactSupport",
};
</script>

<style scoped>
.all {
  text-align: center;
}
.form {
  margin-left: 28%;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
