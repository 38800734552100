import { render, staticRenderFns } from "./contactSupport.vue?vue&type=template&id=08fc6212&scoped=true&"
import script from "./contactSupport.vue?vue&type=script&lang=js&"
export * from "./contactSupport.vue?vue&type=script&lang=js&"
import style0 from "./contactSupport.vue?vue&type=style&index=0&id=08fc6212&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fc6212",
  null
  
)

export default component.exports