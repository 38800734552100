<template>
  <div>
    <div class="title">{{ title }}</div>
    <div class="right">
      <span class="projet" v-on:click="goProject()">Projects</span>
           <div class="vl"></div>
      <span class="projet" v-on:click="goToBuy()">
       <b-icon icon="cart" font-scale="1"></b-icon>
        Buy</span>
      <div class="vl"></div>
      <span>Welcome back</span>
 
     
        <b-dropdown
          size="lg"
          text="Right align"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-avatar
              :variant="getRandomColor()"
              v-text="getInitials()"
            ></b-avatar>
          </template>
           
          <b-dropdown-item><router-link to="/account"> Account </router-link></b-dropdown-item>
           <b-dropdown-item><router-link to="/license"> Licenses </router-link></b-dropdown-item>
           <b-dropdown-item>   <router-link @click.native="logout()" to="/login"> Logout </router-link></b-dropdown-item>
               
        </b-dropdown>
 
      <div class="vl"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerName: "",
    };
  },
  props: ["title"],
  mounted() {
    this.customerName = this.$store.getters.getCustomerName;
  },
  methods: {
    goToBuy() {
      this.$router.push({ path: "/Buy" });
    },
    goProject() {
      this.$router.push({ path: "/homepage" });
    },
    logout() {
      this.$store.commit("removeToken");
      this.$router.push({ path: "/login" });
    },
    getInitials() {
      const customer = this.$store.getters.getCustomerName;
      const stringArray = customer.split(" ");
      const firstLetter = stringArray[0].substring(0, 1);
      const secondLetter = stringArray[1].substring(0, 1);
      return firstLetter.toUpperCase() + secondLetter.toUpperCase();
    },
    getRandomColor() {
      const listOfColors = [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "dark",
      ];
      const randomNumber = Math.floor(Math.random() * 7);
      return listOfColors[randomNumber];
    },
  },
};
</script>

<style scoped>
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;

  color: #2a4192;
}
.right {
  float: right;
}
.img {
  margin-left: 10px;
  margin-right: 46px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #2a4192;
}
.vl {
  display: inline;
  padding-right: 25px;
  border-left: 2px solid #dfe0eb;
  height: 18px;
}
.notif {
  cursor: pointer;
  margin-right: 20px;
}
.projet {
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;

  letter-spacing: 0.3px;
  color: #252733;
  cursor: pointer;
}
</style>
